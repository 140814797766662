const config = {
  companyName: 'City people',

  builder: {
    logo: '',
    theme: {
      palette: {
        primary: {
          lighter: '#7F7E7E',
          light: '#464646',
          main: '#000000',
          dark: '#000000',
          darker: '#030101',
          contrastText: '#FFFFFF',
        },
        secondary: {
          lighter: '#00718B',
          light: '#005A6F',
          main: '#004454',
          dark: '#002C36',
          darker: '#002C49',
          contrastText: '#FFFFFF',
        },
      },
    },
  },
};

export default config;
